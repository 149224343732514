import React, { useEffect } from 'react';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import "../css/agb.css"
import exampleImage from '../assets/imps.png';

const AGBPage: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // Skript erstellen
        const script = document.createElement('script');
        script.src = "https://api.rechtstext.de/public/script";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (


        < div className="admin_page_container" >
            <div className="admin_header" onClick={() => navigate("/")}>
                <div className="admin_header_section admin_logo">
                    <img src={String(logo)} alt='Logo' />
                    <b className="admin_logotext">Traueranzeige.info</b>
                </div>
            </div>


            <div className="rtlc rtlc-9630b341-0b87-4ad4-9da9-7970abee37c7"></div>

            <div className="admin_footer">
                <ul className="admin_footer_links">
                    <li><a href='/login'>Login</a></li>
                    <li><a href="/agb">AGB</a></li>
                    <li><a href="/impressum">Impressum</a></li>
                    <li><a href="/datenschutz">Datenschutz</a></li>
                    <li><a href='/widerruf'> Widerrufsrecht</a></li>
                </ul>
            </div>
        </div >


    )

}

export default AGBPage;
