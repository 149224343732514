import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import imageCompression from 'browser-image-compression';
import Select from 'react-select';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import '../css/EditPostForm.css';
import * as geofire from 'geofire-common';
import { db } from "../database/firebase";

interface EditPostFormProps {
    isOpen: boolean;
    post: any;
    onRequestClose: () => void;
}

type Location = {
    display_name: string;
    lat: string;
    lon: string;
};

type Option = {
    label: string;
    value: Location;
};

const storage = getStorage();

const EditPostForm: React.FC<EditPostFormProps> = ({ isOpen, post, onRequestClose }) => {
    const [name, setName] = useState('');
    const [residence, setResidence] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [locationInput, setLocationInput] = useState('');
    const [selectedLocation, setSelectedLocation] = useState<{ label: string; value: Location } | null>(null);
    const [locationOptions, setLocationOptions] = useState<Option[]>([]);


    useEffect(() => {
        if (post) {
            setName(post.name);
            setResidence(post.residence);
            setDescription(post.description);
            setDate(post.date);
            setPreviewUrl(post.imageUrl);
        }
    }, [post]);

    // New useEffect for location search
    useEffect(() => {
        if (locationInput.length > 2) {
            search(locationInput).then(locations => {
                const options: Option[] = locations.map(location => ({
                    label: location.display_name,
                    value: location,
                }));
                setLocationOptions(options);
            });
        }
    }, [locationInput]);

    const search = async (input: string): Promise<Location[]> => {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&limit=5&q=${input}`,
        );
        const data = await response.json();
        return data;
    };

    const handleLocationChange = (selectedOption: { label: string; value: Location } | null) => {
        setSelectedLocation(selectedOption);
    };


    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];

            if (selectedFile.size > 5 * 1024 * 1024) {
                alert('Die Datei ist zu groß. Bitte wählen Sie eine Datei, die kleiner als 5MB ist.');
            } else {
                setFile(selectedFile);
                setPreviewUrl(URL.createObjectURL(selectedFile));
            }
        }
    };

    const handleCancel = () => {
        setFile(null);
        setPreviewUrl(null);
        onRequestClose();
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setIsLoading(true);

        if (file && selectedLocation) {
            const storageRef = ref(storage, `posts/${post.id}/image/${file?.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    console.error("Upload error: ", error);
                    setIsLoading(false);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                    const options = {
                        maxSizeMB: 0.1,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    };
                    const compressedFile = await imageCompression(file, options);
                    const thumbnailRef = ref(storage, `posts/${post.id}/thumbnail/${file?.name}`);
                    const thumbnailUploadTask = uploadBytesResumable(thumbnailRef, compressedFile);

                    thumbnailUploadTask.on(
                        "state_changed",
                        snapshot => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            setUploadProgress(progress);
                        },
                        (error) => {
                            console.error("Thumbnail upload error: ", error);
                            setIsLoading(false);
                        },
                        async () => {
                            const thumbnailURL = await getDownloadURL(thumbnailUploadTask.snapshot.ref);

                            // Delete previous image and thumbnail
                            if (post.imageUrl) {
                                const imageName = post.imageUrl.split('%2F').pop().split('?')[0];
                                const oldImageRef = ref(storage, `posts/${post.id}/image/${imageName}`);
                                await deleteObject(oldImageRef);
                            }

                            if (post.thumbnailUrl) {
                                const thumbnailName = post.thumbnailUrl.split('%2F').pop().split('?')[0];
                                const oldThumbnailRef = ref(storage, `posts/${post.id}/thumbnail/${thumbnailName}`);
                                await deleteObject(oldThumbnailRef);
                            }
                            const geohashloc = geofire.geohashForLocation([Number(selectedLocation ? selectedLocation.value.lat : post.latitude), Number(selectedLocation ? selectedLocation.value.lon : post.longitude)]);


                            // Update firestore document
                            await updateDoc(doc(db, "posts", post.id), {
                                name: name,
                                residence: selectedLocation.label,
                                latitude: selectedLocation.value.lat,
                                longitude: selectedLocation.value.lon,
                                description: description,
                                date: date,
                                imageUrl: downloadURL,
                                thumbnailUrl: thumbnailURL,
                                updatedAt: serverTimestamp(),
                                geohash: geohashloc
                            });

                            setIsLoading(false);
                            setUploadProgress(0);
                            onRequestClose();
                        }
                    );
                }
            );
        } else {
            const geohashloc = geofire.geohashForLocation([Number(selectedLocation ? selectedLocation.value.lat : post.latitude), Number(selectedLocation ? selectedLocation.value.lon : post.longitude)]);

            await updateDoc(doc(db, "posts", post.id), {
                name: name,
                residence: selectedLocation ? selectedLocation.label : post.residence,
                latitude: selectedLocation ? selectedLocation.value.lat : post.latitude,
                longitude: selectedLocation ? selectedLocation.value.lon : post.longitude,
                description: description,
                date: date,
                updatedAt: serverTimestamp(),
                geohash: geohashloc
            });

            setIsLoading(false);
            onRequestClose();
        }
    };



    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Post bearbeiten"
            overlayClassName={{ base: 'OverlayBase', afterOpen: 'OverlayAfter', beforeClose: 'OverlayBefore' }}
            className={{ base: 'ContentBase', afterOpen: 'ContentAfter', beforeClose: 'ContentBefore' }}
        >
            <div className="edit-post-modal">
                <h2 className="modal-title">Post bearbeiten</h2>
                <form className="innerbox" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            className="text_name"
                            type="text"
                            value={name}
                            onChange={event => setName(event.target.value)}
                            placeholder="Name"
                            required
                        />
                        <Select
                            className="text_location"
                            value={selectedLocation}
                            onInputChange={setLocationInput}
                            onChange={handleLocationChange}
                            options={locationOptions}
                            placeholder="Wohnort"
                            isSearchable
                            required
                        />
                    </div>
                    <input
                        type="date"
                        value={date}
                        onChange={event => setDate(event.target.value)}
                        required
                    />
                    <textarea
                        value={description}
                        onChange={event => setDescription(event.target.value)}
                        placeholder="Beschreibung"
                        required
                    />
                    <div className="form-upload">
                        <input
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFileChange}
                            className="form-file"
                        />
                        <div className="form-preview">
                            {previewUrl ? <img src={previewUrl} alt="preview" /> : "Hier Datei mit Drag and Drop ablegen"}
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="button" onClick={handleCancel} className="button-cancel">Abbrechen</button>
                        <button type="submit" className="button-save">Aktualisieren</button>
                    </div>
                </form>
                {isLoading && (
                    <div className="progress">
                        <div className="progress-bar" style={{ width: `${uploadProgress}%` }} />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default EditPostForm;
