import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, signOut, db } from '../database/firebase';
import { doc, getDoc, collection, onSnapshot, query, orderBy, deleteDoc } from "firebase/firestore";
import '../css/PostManagement.css';
import NewPostForm from './NewPostForm';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import EditPostForm from './EditPostForm';
import { getStorage, ref, deleteObject } from "firebase/storage";


const PostManagement: React.FC = () => {
    const storage = getStorage();
    const navigate = useNavigate();
    const [isNewPostFormOpen, setNewPostFormOpen] = useState(false);
    const [userData, setUserData] = useState<any>(null);
    const [posts, setPosts] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [isEditPostFormOpen, setEditPostFormOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState<any>(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/login");
            } else {
                const docRef = doc(db, "users", user.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserData(docSnap.data());
                } else {
                    console.log("No such document!");
                }
            }
        });

        const postsQuery = query(collection(db, "posts"), orderBy("createdAt", "desc"));
        const unsubscribeFromPosts = onSnapshot(postsQuery, (querySnapshot) => {
            const postsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPosts(postsData);
        });

        // clean up function
        return () => {
            unsubscribe();
            unsubscribeFromPosts();
        };
    }, [navigate]);

    const handleGoHome = () => {
        navigate("/"); // Navigiert zur Hauptseite (angenommen, dies ist die Route zur PostsGrid-Komponente)
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error('Fehler beim Ausloggen', error);
        }
    };

    const handleDelete = async (post: any): Promise<void> => {
        if (window.confirm("Wollen Sie diesen Post wirklich löschen?")) {
            try {
                // Erstelle Speicherreferenzen
                const imageRef = ref(storage, decodeURIComponent(post.imageUrl.split('o/')[1].split('?')[0]));
                const thumbnailRef = ref(storage, decodeURIComponent(post.thumbnailUrl.split('o/')[1].split('?')[0]));

                // Lösche die Bilder aus dem Speicher
                try {
                    await deleteObject(imageRef);
                } catch (error) {
                    console.error('Fehler beim Löschen des Bildes', error);
                }
                try {
                    await deleteObject(thumbnailRef);
                } catch (error) {
                    console.error('Fehler beim Löschen des Thumbnails', error);
                }
                // Lösche das Dokument aus der Firestore-Datenbank
                await deleteDoc(doc(db, "posts", post.id));
            } catch (error) {
                console.error('Fehler beim Löschen des Posts. Teilweise Löschung war möglich', error);
            }
        }
    };

    const generateUserPostsLink = async () => {
        if (auth.currentUser) {
            const userPostsLink = `/userposts/${auth.currentUser.uid}`;
            try {
                await navigator.clipboard.writeText(window.location.origin + userPostsLink)
                alert("Link wurde in die Zwischenablage kopiert: " + window.location.origin + userPostsLink);
            } catch (err) {
                console.error('Fehler beim Kopieren des Links: ', err);
            }
        }
    };




    const handleEdit = async (post: any): Promise<void> => {
        setSelectedPost(post);
        setEditPostFormOpen(true);
    };

    return (
        <div className="post-management-container">
            <div className="sidebar">
                <div className="user-info">
                    {userData && (
                        <>
                            <p className="user-name">{userData.companyName}</p>
                            <p className="user-street">{`${userData.street} ${userData.houseNumber}`}</p>
                            <p className="user-town">{`${userData.postalCode} ${userData.city}`}</p>
                        </>
                    )}
                </div>
                <div className="new-post-button-container">
                    <button onClick={() => setNewPostFormOpen(true)} className="new-post-button">Neuer Post</button>
                </div>
                <div className="new-post-button-container">
                    <button onClick={handleGoHome} className="new-post-button">Home</button>
                </div>
                <div className="new-post-button-container">
                    <button onClick={generateUserPostsLink} className="new-post-button">
                        Mein Institut-Link
                    </button>
                </div>
                <div className="logout-button-container">
                    <button onClick={handleLogout} className="logout-button">Logout</button>
                </div>

            </div>
            <div className="posts-container">
                <div className="search-bar">
                    <input
                        className="search-text"
                        type="text"
                        placeholder="Suche nach Namen..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="table-container">
                    {posts
                        .filter(post => post.name.toLowerCase().includes(searchTerm.toLowerCase()))
                        .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
                        .map(post => (
                            <div key={post.id} className="post">
                                {post.thumbnailUrl && <img className="thumbnail-image" src={post.thumbnailUrl} alt="Post Thumbnail" />}
                                <p>{new Date(post.date).toLocaleDateString('de-DE')}</p>
                                <p>{post.name}</p>
                                <p className="description-text">{post.description}</p>
                                <p>{post.residence}</p>

                                <div className="post-actions">
                                    <button onClick={() => handleEdit(post)}>
                                        <AiFillEdit />
                                    </button>
                                    <button onClick={() => handleDelete(post)}>
                                        <AiFillDelete />
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <EditPostForm
                isOpen={isEditPostFormOpen}
                onRequestClose={() => setEditPostFormOpen(false)}
                post={selectedPost}
            />
            <NewPostForm
                isOpen={isNewPostFormOpen}
                onRequestClose={() => setNewPostFormOpen(false)}
            />
        </div>
    );
}
export default PostManagement;
