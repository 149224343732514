import React, { useEffect, useRef, useState, useCallback } from 'react';
import '../css/PostDetails.css';
import { getDoc, doc } from "firebase/firestore";
import { db } from '../database/firebase';
import { LuShare } from "react-icons/lu";

export type Post = {
    id: string;
    createdAt: string;
    date: string;
    description: string;
    geohash: string;
    imageUrl: string;
    latitude: string;
    longitude: string;
    name: string;
    residence: string;
    thumbnailUrl: string;
    uid: string;
    updatedAt: string;
};

export type User = {
    uid: string;
    city: string;
    companyName: string;
    houseNumber: string;
    postalCode: string;
    street: string;
};

type PostDetailsProps = {
    post: Post;
    onClose: () => void;
};

const PostDetails: React.FC<PostDetailsProps> = ({ post, onClose }) => {
    const [user, setUser] = useState<User | null>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
            onClose();
        }
    }, [onClose]);



    const copyPostLinkToClipboard = async () => {
        const postUrl = `${window.location.origin}/post/${post.id}`;
        try {
            await navigator.clipboard.writeText(postUrl);
            alert('Link kopiert: ' + postUrl); // Optional: Benachrichtigung, dass der Link kopiert wurde
        } catch (err) {
            console.error('Fehler beim Kopieren des Links: ', err);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    React.useEffect(() => {
        const fetchUser = async () => {
            const userDoc = await getDoc(doc(db, 'users', post.uid));
            if (userDoc.exists()) {
                setUser(userDoc.data() as User);
            }
        };

        fetchUser();
    }, [post]);

    return (
        <div className='modal-wrapper'>
            <div className='modal-content' ref={modalContentRef}>
                <div className="title-share-container">
                    <h2 className='post-title'>{post.name}</h2>
                    <LuShare onClick={copyPostLinkToClipboard} className='share-icon' />
                </div>
                <img src={post.imageUrl} alt="Post" className='post-image' />
                <p className='post-description'>{post.description}</p>
                <div className='row'>
                    <p>{post.date && "Verstorben am " + (post.date) + " in " + (post.residence)}</p>

                </div>

                {user && (
                    <div>
                        <h2 className='small-text'> {user.companyName}, {user.street} {user.houseNumber}, {user.postalCode} {user.city}</h2>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PostDetails;
