import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import { Puff } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import PostDetails, { Post } from '../user_sites/PostDetails';
import { fetchInstitutData, fetchInstitutePosts } from '../database/firebaseQueries';
import logo from '../assets/logo.png';
import endOfDataImage from '../assets/end_of_data.jpeg';
import { useNavigate } from 'react-router-dom';
import '../css/PostsGrid.css';


const InstPostsGrid: React.FC = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [posts, setPosts] = useState<any[]>([]);
    const [userData, setUserData] = useState<any>(null);
    const [selectedPost, setSelectedPost] = useState<Post | null>(null);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    useEffect(() => {
        if (userId) {
            fetchInstitutePosts(userId).then(newPosts => {
                setPosts(newPosts);
                setIsDataLoading(false);
            });
            fetchInstitutData(userId).then(userData => {
                setUserData(userData);
            });
        }
    }, [userId]);

    return (
        <div className="posts-grid-container">

            <div className="header">
                <div className="header-section logo" onClick={() => navigate("/")}>
                    <img src={logo} alt='Logo' />
                    <b className="logotext">{userData ? `${userData.companyName}` : ''}</b>

                </div>
                {userData && (
                    <p className="institution-address">
                        {userData.street} {userData.houseNumber}, {userData.postalCode} {userData.city}
                    </p>
                )}

            </div>



            {
                isDataLoading ? (
                    <div className="spinner-container">
                        <Puff color="#7a7a7a" height={100} width={100} />
                    </div>
                ) : (
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {posts.map(post => (
                            <div key={post.id} className="post" onClick={() => post.isRandomImage ? null : setSelectedPost(post)}>
                                {post.imageUrl && <img src={post.imageUrl} alt="Post" />}
                            </div>
                        ))}
                        <div className="post end-of-data">
                            <img src={endOfDataImage} alt="Ende der Daten" />
                        </div>
                    </Masonry>
                )
            }

            {selectedPost && <PostDetails post={selectedPost} onClose={() => setSelectedPost(null)} />}

            <div className="footer">
                <ul className="footer-links">
                    <li><a href='/login'>Login</a></li>
                    <li><a href="/agb">AGB</a></li>
                    <li><a href="/impressum">Impressum</a></li>
                    <li><a href="/datenschutz">Datenschutz</a></li>
                </ul>
            </div>
        </div >
    );
};

export default InstPostsGrid;
