import React, { useEffect } from 'react';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import "../css/ContactPage.css"
import exampleImage from '../assets/imps.png';

const CoockieRichtlinie: React.FC = () => {
    useEffect(() => {
        // Skript erstellen
        const script = document.createElement('script');
        script.src = "https://api.rechtstext.de/public/script";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const navigate = useNavigate();
    return (
        <div className="contact-page-container">
            {/* Header */}
            <script src="https://mein.clickskeks.at/app.js?apiKey=ac27007f7ed4e8f5176b557a8cfc079a71ed61960b21ea05&amp;domain=65db83a22cdce514e24ec3aa" referrerPolicy="origin"></script>
            <div className="contact-header" onClick={() => navigate("/")}>
                <div className="contact-header-section logo">
                    <img src={String(logo)} alt='Logo' />
                    <b className="logotext">Traueranzeige.info</b>
                </div>
                <div className="header-section contact-navigation"></div>
                <div className="header-section search"></div>
            </div>
            <div className="rtlc rtlc-9630b341-0b87-4ad4-9da9-7970abee37c7"></div>
            {/* Kontaktinhalt */}
            <div className="contact-content">
            </div>

            {/* Footer */}
            <div className="footer">
                <ul className="footer-links">
                    <li><a href='/login'>Login</a></li>
                    <li><a href="/agb">AGB</a></li>
                    <li><a href="/impressum">Impressum</a></li>
                    <li><a href="/datenschutz">Datenschutz</a></li>
                    <li><a href='/widerruf'> Widerrufsrecht</a></li>
                </ul>
            </div>
        </div>
    );
};

export default CoockieRichtlinie;
