// PostsGrid.tsx
import React, { useState, useEffect } from 'react';
import '../css/PostsGrid.css';
import Masonry from 'react-masonry-css';
import logo from '../assets/logo.png';
import Select from 'react-select';
import endOfDataImage from '../assets/end_of_data.jpeg';
import { Puff } from 'react-loader-spinner';
import { Post } from './PostDetails';
import PostDetails from './PostDetails';
import { useParams } from 'react-router-dom';
import { fetchPostsandRandImg } from '../database/firebaseQueries';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../database/firebase.js';


type Location = {
    display_name: string;
    lat: string;
    lon: string;
};

const radiusOptions = [
    { value: 5, label: '5 km' },
    { value: 10, label: '10 km' },
    { value: 15, label: '15 km' },
    { value: 20, label: '20 km' },
];

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
};

const PostsGrid: React.FC = () => {
    const { postId } = useParams();
    const [posts, setPosts] = useState<any[]>([]);
    const [selectedPost, setSelectedPost] = useState<Post | null>(null);
    const [locationInput, setLocationInput] = useState('');
    const [isLocationLoading, setIsLocationLoading] = useState(false); // für Standort-API
    const [isDataLoading, setIsDataLoading] = useState(false); // für Firebase-Abfrage
    const [isLocationSearchCompleted, setIsLocationSearchCompleted] = useState(true);
    const [selectedRadius, setSelectedRadius] = useState<{ label: string, value: number }>({ label: '5 km', value: 5 });
    const handleRadiusChange = (selectedOption: { label: string, value: number } | null) => {
        if (selectedOption) setSelectedRadius(selectedOption);
    };

    const loadPostLocation = async (postId: string) => {
        const postDoc = await getDoc(doc(db, "posts", postId));
        if (postDoc.exists()) {
            const postData = postDoc.data();
            return { lat: postData.latitude, lon: postData.longitude };
        }
        return null;
    };

    const loadPostDetails = async (postId: string) => {
        const postDoc = await getDoc(doc(db, "posts", postId));
        if (postDoc.exists()) {
            return { id: postId, ...postDoc.data() } as Post;
        }
        return null;
    };

    const [selectedLocation, setSelectedLocation] = useState<{ label: string, value: { lat: number, lon: number } } | null>(null);
    const [locationOptions, setLocationOptions] = useState<{ label: string, value: { lat: number, lon: number } }[]>([]);

    const search = async (input: string): Promise<Location[]> => {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&limit=5&q=${input}`,
        );
        const data = await response.json();
        return data;
    };

    useEffect(() => {
        if (postId) {
            loadPostLocation(postId).then(location => {
                if (location) {
                    setSelectedLocation({ label: `${location.lat}, ${location.lon}`, value: { lat: parseFloat(location.lat), lon: parseFloat(location.lon) } });
                }
            });
            loadPostDetails(postId).then(post => {
                if (post) {
                    setSelectedPost(post);
                }
            });
        }
    }, [postId]);


    useEffect(() => {
        if (locationInput.length > 3) {
            setIsLocationLoading(true);
            setIsLocationSearchCompleted(false);
            search(locationInput).then(locations => {
                const options = locations.map(location => ({
                    label: location.display_name,
                    value: {
                        lat: parseFloat(location.lat),
                        lon: parseFloat(location.lon)
                    },
                }));
                setLocationOptions(options);
                setIsLocationLoading(false);
                setIsLocationSearchCompleted(true);
            });

        }
    }, [locationInput]);

    useEffect(() => {
        if (selectedLocation && selectedRadius) {
            setIsDataLoading(true);
            fetchPostsandRandImg(selectedLocation, selectedRadius).then(newPosts => {
                setPosts(newPosts);
                setIsDataLoading(false);
            });
        }
    }, [selectedLocation, selectedRadius]);



    return (
        <div className="posts-grid-container">
            <div className="header">
                <div className="header-section logo_postgrid">
                    <img src={String(logo)} alt='Logo' />
                    <b className="logotext">Traueranzeige.info</b>
                </div>
                <div className="header-section navigation"></div>
                <div className="header-section search">
                    <div className='searchfield'>
                        <Select
                            className="text_location"
                            value={selectedLocation}
                            onInputChange={setLocationInput}
                            onChange={setSelectedLocation}
                            options={locationOptions}
                            placeholder="Wohnort"
                            isSearchable
                            isLoading={isLocationLoading}
                            noOptionsMessage={() => isLocationLoading || !isLocationSearchCompleted ? "Lädt..." : "Mal schauen ob ich noch was finde..."}
                            required
                        />
                        <Select
                            value={selectedRadius}
                            onChange={handleRadiusChange}  // updated onChange
                            options={radiusOptions}
                            className="text_radius"
                            placeholder="Radius"
                            required
                        />
                    </div>
                </div>
            </div>

            {isDataLoading ? (
                <div className="spinner-container">
                    <Puff
                        color="#7a7a7a"
                        height={100}
                        width={100}
                    />
                </div>
            ) : (
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {posts.map(post => (
                        <div
                            key={post.id}
                            className="post"
                            onClick={() => { if (!post.isRandomImage) setSelectedPost(post) }}
                        >
                            {post.imageUrl && <img src={post.imageUrl} alt="Post" />}
                        </div>

                    ))}
                    <div className="post end-of-data">
                        <img src={endOfDataImage} alt="Ende der Daten" />
                    </div>

                </Masonry>

            )
            }
            {selectedPost && (
                <PostDetails
                    post={selectedPost}
                    onClose={() => setSelectedPost(null)}
                />
            )}

            <div className="footer">
                <ul className="footer-links">
                    <li><a href='/login'>Login</a></li>
                    <li><a href="/agb">AGB</a></li>
                    <li><a href="/impressum">Impressum</a></li>
                    <li><a href="/datenschutz">Datenschutz</a></li>
                    <li><a href='/widerruf'> Widerrufsrecht</a></li>
                </ul>
            </div>
        </div>
    );
};

export default PostsGrid;
