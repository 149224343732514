import React, { useEffect } from 'react';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import "../css/ContactPage.css"
import exampleImage from '../assets/imps.png';

const ContactPage: React.FC = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Skript erstellen
        const script = document.createElement('script');
        script.src = "https://api.rechtstext.de/public/script";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <div className="contact-page-container">
            {/* Header */}
            <div className="contact-header" onClick={() => navigate("/")}>
                <div className="contact-header-section logo">
                    <img src={String(logo)} alt='Logo' />
                    <b className="logotext">Traueranzeige.info</b>
                </div>
                <div className="header-section contact-navigation"></div>
                <div className="header-section search"></div>
            </div>
            <div className="rtlc rtlc-764f4c38-1796-4f1e-bb85-a9d98e173d7f"></div>
            {/* Kontaktinhalt */}
            <div className="contact-content">
                <div className="contact-image">
                    <img src={exampleImage} alt="Beispielbild" />
                </div>
                <div className="contact-info">
                    <h1>Impressum</h1>
                    <table className="contact-table">
                        {<tbody>
                            <tr>
                                <td>Firma:</td>
                                <td>JB Holding GmbH</td>
                            </tr>
                            <tr>
                                <td>Adresse:</td>
                                <td>Turmstr 17, 72348 Rosenfed</td>
                            </tr>
                            <tr>
                                <td>E-Mail:</td>
                                <td>info@traueranzeige.info</td>
                            </tr>
                            <tr>
                                <td>Website:</td>
                                <td>traueranzeige.info</td>
                            </tr>
                            <tr>
                                <td>Handelsregister:</td>
                                <td>HRB790036</td>
                            </tr>
                        </tbody>}
                    </table>
                </div>
            </div>

            {/* Footer */}
            <div className="footer">
                <ul className="footer-links">
                    <li><a href='/login'>Login</a></li>
                    <li><a href="/agb">AGB</a></li>
                    <li><a href="/impressum">Impressum</a></li>
                    <li><a href="/datenschutz">Datenschutz</a></li>
                    <li><a href='/widerruf'> Widerrufsrecht</a></li>
                </ul>
            </div>
        </div>
    );
};

export default ContactPage;
