import React, { useState } from 'react';
import { auth, db } from '../database/firebase.js';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import '../css/Register.css';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import img from '../assets/login.png';
import '../css/Login.css'; // Erstellen Sie eine entsprechende CSS-Datei

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const passwordValidation = (password: string) => {
        const regex = /^(?=.*\d).{8,}$/;
        return regex.test(password);
    };

    const register = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!passwordValidation(password)) {
            setError('Das Passwort muss mindestens 8 Zeichen lang sein und mindestens eine Zahl enthalten.');
            return;
        }

        setLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const userId = userCredential.user?.uid;
            if (userId) {
                await setDoc(doc(db, "users", userId), {
                    companyName,
                    street,
                    houseNumber,
                    postalCode,
                    city
                });
            }
            setEmail('');
            setPassword('');
            setCompanyName('');
            setStreet('');
            setHouseNumber('');
            setPostalCode('');
            setCity('');
            navigate('/postmanagement');
        } catch (error: any) {
            const { message } = error as { message: string };
            setError(message);
        } finally {
            setLoading(false);
        }
    };

    const goToLogin = () => {
        navigate('/login'); // Aktualisieren Sie dies mit dem tatsächlichen Pfad zur Registrierungsseite in Ihrer Anwendung
    }

    return (
        <div className="contact-page-container">

            {/* Header */}
            <div className="contact-header" onClick={() => navigate("/")}>
                <div className="contact-header-section logo">
                    <img src={String(logo)} alt='Logo' />
                    <b className="logotext">Traueranzeige.info</b>
                </div>
                <div className="header-section contact-navigation"></div>
                <div className="header-section search"></div>
            </div>

            <div className="contact-content">
                <div className="contact-image">
                    <img src={img} alt="Beispielbild" />
                </div>
                <div className="contact-info">
                    <h1 className="title">Registrierung Unternehmen</h1>
                    {error && <p>{error}</p>}
                    {loading ? (
                        <div>Loading...</div> // Hier können Sie Ihren Ladekreis einfügen
                    ) : (
                        <form className="register-form" onSubmit={register}>
                            <input type='text' placeholder='Firmenname' value={companyName} onChange={(e) => setCompanyName(e.target.value)} required disabled={loading} />
                            <input type='text' placeholder='Straße' value={street} onChange={(e) => setStreet(e.target.value)} required disabled={loading} />
                            <input type='text' placeholder='Hausnummer' value={houseNumber} onChange={(e) => setHouseNumber(e.target.value)} required disabled={loading} />
                            <input type='text' placeholder='PLZ' value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required disabled={loading} />
                            <input type='text' placeholder='Ort' value={city} onChange={(e) => setCity(e.target.value)} required disabled={loading} />
                            <input type='email' placeholder='Email-Adresse' value={email} onChange={(e) => setEmail(e.target.value)} required disabled={loading} />
                            <input type='password' placeholder='Passwort' value={password} onChange={(e) => setPassword(e.target.value)} required disabled={loading} />
                            <button type='submit' className="submit-btn" disabled={loading}>Registrieren</button>
                            <div className="needlogin">Bereits einen Account?</div>
                            <button type='submit' className="register-btn" onClick={goToLogin}>Hier gehts zum Login</button>
                        </form>
                    )}
                </div>
            </div>
            {/* Footer */}
            <div className="footer">
                <ul className="footer-links">
                    <li><a href='/login'>Login</a></li>
                    <li><a href="/agb">AGB</a></li>
                    <li><a href="/impressum">Impressum</a></li>
                    <li><a href="/datenschutz">Datenschutz</a></li>
                </ul>
            </div>

        </div>

    );
}

export default Register;
