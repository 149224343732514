import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import imageCompression from 'browser-image-compression';
import { doc, updateDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import '../css/NewPostForm.css';
import * as geofire from 'geofire-common';


import { db } from "../database/firebase";

interface NewPostFormProps {
    isOpen: boolean;
    onRequestClose: () => void;
}
type Location = {
    display_name: string;
    lat: string;
    lon: string;
};

type Option = {
    label: string;
    value: Location;
};

const storage = getStorage();

const NewPostForm: React.FC<NewPostFormProps> = ({ isOpen, onRequestClose }) => {
    const [userId, setUserId] = useState<string | null>(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [locationInput, setLocationInput] = useState('');
    const [selectedLocation, setSelectedLocation] = useState<{ label: string; value: Location } | null>(null);
    const [locationOptions, setLocationOptions] = useState<Option[]>([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);


    // Update location options whenever the location input changes
    useEffect(() => {
        if (locationInput.length > 2) {
            search(locationInput).then(locations => {
                const options: Option[] = locations.map(location => ({
                    label: location.display_name,
                    value: location,
                }));
                setLocationOptions(options);
            });
        }
    }, [locationInput]);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, user => {
            if (user) {
                setUserId(user.uid);
                setIsAuthenticated(true);
            } else {
                setUserId(null);
                setIsAuthenticated(false);
            }
        });
    }, []);


    const search = async (input: string): Promise<Location[]> => {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&limit=5&q=${input}`,
        );
        const data = await response.json();
        return data;
    };

    const handleLocationChange = (selectedOption: { label: string; value: Location } | null) => {
        setSelectedLocation(selectedOption);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];

            if (selectedFile.size > 5 * 1024 * 1024) {
                alert('Die Datei ist zu groß. Bitte wählen Sie eine Datei, die kleiner als 5MB ist.');
            } else {
                setFile(selectedFile);
                setPreviewUrl(URL.createObjectURL(selectedFile));
            }
        }
    };

    const handleCancel = () => {
        setFile(null);
        setPreviewUrl(null);
        onRequestClose();
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (file && selectedLocation && isAuthenticated) {
            setIsLoading(true);
            // Generate Geohash for given latitude and longitude
            const geohash = geofire.geohashForLocation([Number(selectedLocation.value.lat), Number(selectedLocation.value.lon)]);

            const postDoc = await addDoc(collection(db, "posts"), {
                uid: userId,  // Attach the uid to the document
                name: name,
                residence: selectedLocation.label,
                latitude: selectedLocation.value.lat,
                longitude: selectedLocation.value.lon,
                description: description,
                date: date,
                createdAt: serverTimestamp(),
                geohash: geohash  // Store the geohash in the document
            });

            const fileExtension = file.name.split('.').pop();
            const imageId = `${uuidv4()}.${fileExtension}`;
            const postId = postDoc.id;
            const storageRef = ref(storage, `posts/${postId}/image/${imageId}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    console.error("Upload error: ", error);
                    setIsLoading(false);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                    const options = {
                        maxSizeMB: 0.1,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    };
                    const compressedFile = await imageCompression(file, options);
                    const thumbnailRef = ref(storage, `posts/${postId}/thumbnail/${imageId}`);
                    const thumbnailUploadTask = uploadBytesResumable(thumbnailRef, compressedFile);

                    thumbnailUploadTask.on(
                        "state_changed",
                        snapshot => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            setUploadProgress(progress);
                        },
                        (error) => {
                            console.error("Thumbnail upload error: ", error);
                            setIsLoading(false);
                        },
                        async () => {
                            const thumbnailURL = await getDownloadURL(thumbnailUploadTask.snapshot.ref);
                            await updateDoc(doc(db, "posts", postId), {
                                name: name,
                                residence: selectedLocation.label, // change this line
                                description: description,
                                date: date,
                                imageUrl: downloadURL,
                                thumbnailUrl: thumbnailURL,
                                createdAt: serverTimestamp(),
                            });
                            setIsLoading(false);
                            setUploadProgress(0);
                            onRequestClose();
                        }
                    );
                }
            );
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Neuer Post"
            overlayClassName={{ base: 'OverlayBase', afterOpen: 'OverlayAfter', beforeClose: 'OverlayBefore' }}
            className={{ base: 'ContentBase', afterOpen: 'ContentAfter', beforeClose: 'ContentBefore' }}
        >
            <div className="new-post-modal">
                <h2 className="modal-title">Neuer Post erstellen</h2>
                <form className="innerbox" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            className="text_name"
                            type="text"
                            value={name}
                            onChange={event => setName(event.target.value)}
                            placeholder="Name"
                            required
                        />
                        <Select
                            className="text_location"
                            value={selectedLocation}
                            onInputChange={setLocationInput}
                            onChange={handleLocationChange}
                            options={locationOptions}
                            placeholder="Wohnort"
                            isSearchable
                            required
                        />
                    </div>

                    <input
                        type="date"
                        value={date}
                        onChange={event => setDate(event.target.value)}
                        required
                    />
                    <textarea
                        value={description}
                        onChange={event => setDescription(event.target.value)}
                        placeholder="Beschreibung"
                        required
                    />
                    <div className='previewtext'>Vorschau</div>
                    <div className="form-upload">
                        <input
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFileChange}
                            className="form-file"
                        />
                        <div className="form-preview">
                            {previewUrl ? <img className="imgview" src={previewUrl} alt="preview" /> : "Hier Datei mit Drag and Drop ablegen"}
                        </div>
                    </div>
                    <div className='noticetext'>versuchen sie Ränder zu vermeiden</div>
                    <div className="form-group">
                        <button type="button" onClick={handleCancel} className="button-cancel">Abbrechen</button>
                        <button type="submit" className="button-save">Senden</button>
                    </div>
                </form>
                {isLoading && (
                    <div className="progress">
                        <div className="progress-bar" style={{ width: `${uploadProgress}%` }} />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default NewPostForm;
