import React, { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../database/firebase.js';
import logo from '../assets/logo.png';
import img from '../assets/login.png';
import '../css/Login.css'; // Erstellen Sie eine entsprechende CSS-Datei

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const login = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log(userCredential); // Zur Überprüfung
            setEmail('');
            setPassword('');
            navigate('/postmanagement');
        } catch (error: any) {
            console.log(error.message);
        }
    };
    const goToRegister = () => {
        navigate('/register'); // Aktualisieren Sie dies mit dem tatsächlichen Pfad zur Registrierungsseite in Ihrer Anwendung
    }
    return (
        <div className="contact-page-container">
            {/* Header */}
            <div className="contact-header" onClick={() => navigate("/")}>
                <div className="contact-header-section logo">
                    <img src={String(logo)} alt='Logo' />
                    <b className="logotext">Traueranzeige.info</b>
                </div>
                <div className="header-section contact-navigation"></div>
                <div className="header-section search"></div>
            </div>

            <div className="contact-content">
                <div className="contact-image">
                    <img src={img} alt="Beispielbild" />
                </div>
                <div className="contact-info">
                    <div className="contentlogin">
                        <h1 className="title">Login Unternehmen</h1>
                        <form className="login-form" onSubmit={login}>
                            <input type='email' placeholder='Email-Adresse' value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <input type='password' placeholder='Passwort' value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <button type='submit' className="submit-btn">Anmelden</button>
                        </form>

                        <div className="needregistration">Noch kein Account?</div>
                        <button type='submit' className="register-btn" onClick={goToRegister}>Erstelle hier einen Account</button>
                    </div >
                </div>
            </div>

            {/* Footer */}
            <div className="footer">
                <ul className="footer-links">
                    <li><a href='/login'>Login</a></li>
                    <li><a href="/agb">AGB</a></li>
                    <li><a href="/impressum">Impressum</a></li>
                    <li><a href="/datenschutz">Datenschutz</a></li>
                </ul>
            </div>


        </div >
    );
}

export default Login;
