function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

type ImagesType = {
    [key: string]: string;
};
const images: ImagesType = importAll(require.context('./assets/fillimages', false, /\.(jpg|jpeg|png|svg)$/));

export default images;