// AppRouter.tsx
import { useEffect, useState } from 'react';
import './css/AppRouter.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from './database/firebase'; // Importiere die Firebase Auth
import Register from './institution_sites/Register';
import Login from './institution_sites/Login';
import PostManagement from './institution_sites/PostManagement';
import PostsGrid from './user_sites/PostsGrid';
import { Navigate } from 'react-router-dom';
import InstPostsGrid from "./institution_sites/Inst_Post_Grid";
import ContactPage from "./legal_and_help/contact";
import AGBPage from './legal_and_help/agb';
import Datenschutz from './legal_and_help/datenschutz';
import CookieRichtlinie from './legal_and_help/cookie-richtlinie';
import Widerruf from './legal_and_help/widerruf';

const AppRouter = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsAuthenticated(!!user);
        });
        return () => unsubscribe();
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/register" element={isAuthenticated ? <Navigate to="/postmanagement" /> : <Register />} />
                <Route path="/login" element={isAuthenticated ? <Navigate to="/postmanagement" /> : <Login />} />
                <Route path="/postmanagement" element={!isAuthenticated ? <Navigate to="/login" /> : <PostManagement />} />
                <Route path="/" element={<PostsGrid />} />
                <Route path="/post/:postId" element={<PostsGrid />} />
                <Route path="/userposts/:userId" element={<InstPostsGrid />} />
                <Route path="/impressum" element={<ContactPage />} />
                <Route path="/agb" element={<AGBPage />} />
                <Route path='/datenschutz' element={<Datenschutz />} />
                <Route path='/cookie-richtlinie' element={< CookieRichtlinie />} />
                <Route path='/widerruf' element={< Widerruf />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;

