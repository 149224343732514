import { collection, getDocs, query, orderBy, startAt, endAt, where, limit } from "firebase/firestore";
import { db } from './firebase';
import { geohashQueryBounds, distanceBetween } from 'geofire-common';
import moment from "moment"
import images from '../randomimage';
import { doc, getDoc } from 'firebase/firestore';



const getRandomImage = (usedRandomImages: string[]): string | null => {
    const availableImages = Object.keys(images).filter(key => !usedRandomImages.includes(key));
    if (availableImages.length === 0) {
        return null;  // Keine verfügbaren Bilder mehr
    }
    const randomKey = availableImages[Math.floor(Math.random() * availableImages.length)];
    return images[randomKey];
};

type LocationValue = {
    lat: number;
    lon: number;
};

type SelectedLocation = {
    value: LocationValue;
};

type SelectedRadius = {
    value: number;
};

type PostDoc = {
    id: string;
    customerdate: moment.Moment;
    [key: string]: any;
};



export const fetchPostsandRandImg = async (
    selectedLocation: SelectedLocation,
    selectedRadius: SelectedRadius,
    usedRandomImages: string[] = []
) => {
    const center: [number, number] = [selectedLocation.value.lat, selectedLocation.value.lon];
    const radiusInM = selectedRadius.value * 1000;
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = bounds.map(b => {
        const q = query(
            collection(db, 'posts'),
            orderBy('geohash'),
            startAt(b[0]),
            endAt(b[1]),
            limit(50)
        );
        return getDocs(q);
    });

    const snapshots = await Promise.all(promises);
    let matchingDocs: PostDoc[] = [];
    snapshots.forEach(snap => {
        snap.docs.forEach(doc => {
            const lat = parseFloat(doc.get('latitude'));
            const lng = parseFloat(doc.get('longitude'));
            const distanceInKm = distanceBetween([lat, lng], center);
            const distanceInM = distanceInKm * 1000;

            if (distanceInM <= radiusInM) {
                matchingDocs.push({ id: doc.id, customerdate: moment(doc.get('date'), "yyyy-MM-DD"), ...doc.data() });
            }
        });
    });

    matchingDocs.sort((a, b) => b.customerdate.valueOf() - a.customerdate.valueOf());
    return addRandomImagesToPosts(matchingDocs, usedRandomImages);
};


export const fetchInstitutePosts = async (userId: string, usedRandomImages: string[] = []) => {
    const q = query(collection(db, "posts"), where("uid", "==", userId), limit(50));
    const querySnapshot = await getDocs(q);
    const posts = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
            id: doc.id,
            customerdate: data.customerdate ? moment(data.customerdate, "yyyy-MM-DD") : moment(),
            ...data
        };
    });
    return addRandomImagesToPosts(posts, usedRandomImages);
}


export const fetchInstitutData = async (userId: string) => {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
        return userDoc.data();
    } else {
        console.log("Keine Benutzerdaten gefunden für: ", userId);
        return null;
    }
};


const addRandomImagesToPosts = (posts: PostDoc[], usedRandomImages: string[]): PostDoc[] => {
    // Chronologische Sortierung der Postbilder
    let newPosts = [...posts].sort((a, b) => b.customerdate.valueOf() - a.customerdate.valueOf());

    // Berechnen der Anzahl der Randombilder
    const totalImagesNeeded = Math.max(16, newPosts.length);
    const numberOfRandomImages = totalImagesNeeded - newPosts.length;

    // Erzeugen und Einfügen der Randombilder
    for (let i = 0; i < numberOfRandomImages; i++) {
        const randomImage = getRandomImage(usedRandomImages);
        if (!randomImage) break; // Beenden, wenn keine Randombilder mehr verfügbar sind

        const randomKey = Object.keys(images).find(key => images[key] === randomImage);
        if (randomKey) {
            usedRandomImages.push(randomKey);
            const randomImageObj = {
                id: `randomImage-${randomKey}`,
                imageUrl: randomImage,
                isRandomImage: true,
                customerdate: moment()
            };

            // Einfügen des Randombildes an einer zufälligen Position im Array
            const position = Math.floor(Math.random() * newPosts.length);
            newPosts.splice(position, 0, randomImageObj);
        }
    }

    return newPosts;
};



