// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-link,
.login-link {
    display: inline-block;
    margin-right: 1rem;
    color: #fff;
    text-align: center;
    padding: 0.8rem;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    background-color: #1976d2;
    cursor: pointer;
}

.register-link:last-child,
.login-link:last-child {
    margin-right: 0;
}

.register-link:hover,
.login-link:hover {
    background-color: #0d47a1;
    transform: scale(1.05);
}

nav {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/css/AppRouter.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,6BAA6B;IAC7B,yBAAyB;IACzB,eAAe;AACnB;;AAEA;;IAEI,eAAe;AACnB;;AAEA;;IAEI,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".register-link,\n.login-link {\n    display: inline-block;\n    margin-right: 1rem;\n    color: #fff;\n    text-align: center;\n    padding: 0.8rem;\n    text-decoration: none;\n    font-size: 16px;\n    border-radius: 5px;\n    transition: all 0.3s ease-out;\n    background-color: #1976d2;\n    cursor: pointer;\n}\n\n.register-link:last-child,\n.login-link:last-child {\n    margin-right: 0;\n}\n\n.register-link:hover,\n.login-link:hover {\n    background-color: #0d47a1;\n    transform: scale(1.05);\n}\n\nnav {\n    display: flex;\n    justify-content: flex-end;\n    padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
